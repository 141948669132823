<div class="container">

    <h3>Login</h3>
    <br />
    <form #myForm="ngForm">
        <div class="form-group row" [class.has-success]="!email.invalid"
             [class.has-error]="email.invalid && email.touched">
            <label for="email" class="col-sm-2 col-form-label">Email<span class="text-danger">*</span></label>
            <div class="col-sm-10">
                <input class="form-control" type="text" id="email" name="email" required [(ngModel)]="user.email"
                       #email="ngModel" />
            </div>
        </div>

        <div class="form-group row" [class.has-success]="!password.invalid"
             [class.has-error]="password.invalid && password.touched">
            <label for="password1" class="col-sm-2 col-form-label">Passwort<span class="text-danger">*</span></label>
            <div class="col-sm-10">
                <input class="form-control" type="password" id="password" name="password" required
                       [(ngModel)]="user.password" #password="ngModel" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Angemeldet bleiben</label>
            <div class="col-sm-10">
                <input type="checkbox" name="remember" [(ngModel)]="user.remember" />
            </div>
        </div>

        <button class="btn btn-primary" (click)="login(user)"
                [disabled]="(email.invalid || password.invalid || loading) && password.touched">
            Anmelden
        </button>
        &nbsp;<img *ngIf="loading" src="/assets/images/ajax-loader.gif" alt="icon" />
    </form>

</div>
