import { Injectable, isDevMode } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { User } from './user';

import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    loginEventSource: Subject<User> = new Subject<User>();
    loginEvent$ = this.loginEventSource.asObservable();
    public user: User;

    loginEvent(user: User) {
        if (isDevMode()) { console.log('loginEvent ' + user); }
        this.user = user;
        this.loginEventSource.next(user);
    }

    constructor(private http: HttpClient) { }

    login(user: User, site: string): Observable<User> {
        return this.http.post<User>(environment.baseUrl + '/v1/' + site + '/login', user);
    }

    logout(): Observable<string> {
        return this.http.post<string>(environment.baseUrl + '/v1/logout', null);
    }

    getUser(site: string): Observable<User> {
        return this.http.get<User>(environment.baseUrl + '/v1/' + site + '/login/info');
    }
}
