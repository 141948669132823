<div *ngIf="loading">
    <img class="center-block" src="/assets/loader.gif" />
</div>

<div class="form-group">
    <label for="rechnungsposten">Stammdaten</label>
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <td scope="col">Name</td>
                <td colspan="2" scope="col">Adresse</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of customers; let i = index">
                <td><a routerLink="/{{site}}/master/{{item.customerId}}">{{item.label}}</a></td>
                <td>
                    <small>{{item.address.street}}<br />{{item.address.zip}} {{item.address.city}}</small>
                </td>
                <td class="nopad"><a routerLink="/{{site}}/invoice/new/{{item.customerId}}"><span
                            class="material-icons">receipt</span></a></td>
            </tr>

        </tbody>
    </table>
</div>

<div class="form-group">
    <button type="button" class="btn btn-primary" (click)="insert()">Neu</button>
</div>