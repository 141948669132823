<div *ngIf="!item">
    <img class="center-block" src="/assets/loader.gif" />
</div>

<div *ngIf="item">
    <form #myForm="ngForm">
        <div class="form-group">
            <label for="text">Text</label>
            <input class="form-control" name="text" type=text [(ngModel)]="item.text">
        </div>
        <div class="form-group">
            <label for="text">Rechnungsposten</label>
            <input class="form-control" name="name" type=text [(ngModel)]="item.invoiceItem.name">
        </div>
        <div class="form-group">
            <label for="text">Kosten</label>
            <input class="form-control" name="cost" type=number [(ngModel)]="item.invoiceItem.cost">
        </div>
        <div class="form-group">
            <label for="text">Anzahl</label>
            <input class="form-control" name="quantity" type=number [(ngModel)]="item.invoiceItem.quantity">
        </div>

        <div class="container">
            <div class="row in-button-footer">
                <div class="col">
                    <button type="button" class="btn btn-primary" (click)="update()">Aktualisieren</button>
                    <button type="button" class="btn btn-primary" (click)="cancel()">Abbrechen</button>
                </div>
                <div class="col">
                    <button *ngIf="updateMode" type="button" class="btn btn-danger" (click)="delete()"><span
                            class="material-icons">delete</span></button>
                </div>
            </div>
        </div>

    </form>
</div>