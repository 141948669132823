import { Component, OnInit, isDevMode } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

import { LoginService } from './login.service';
import { User } from './user';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    site: string;
    user: User;
    loading: boolean;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private loginService: LoginService
    ) { }

    ngOnInit() {
        this.site = this.route.snapshot.paramMap.get('site');
        this.user = new User();
    }

    login(user: User) {
        this.loading = true;
        if (isDevMode()) { console.log('login=' + user.name + '/' + this.site); }
        this.loginService.login(user, this.site).subscribe(
            u => {
                this.loading = false;
                this.loginService.loginEvent(u);
                if (isDevMode()) { console.log('user ' + u.name + ' authenticated'); }
                this.router.navigate(['/' + this.site + '/list']);
            },
            error => {
                if (isDevMode()) { console.log('login failed'); }
                this.loading = false;
                if (error instanceof HttpErrorResponse) {
                    if (isDevMode()) { console.log(JSON.stringify(error)); }
                    if (error.status === 401) {
                        this.toastr.error(error.error.text, 'Login Fehler');
                    } else {
                        this.toastr.error(error.error.text, 'Fehler aufgetreten');
                    }
                } else {
                    this.toastr.error(error.error.text, 'Fehler aufgetreten');
                }
            }
        );
    }
}
