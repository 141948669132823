import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatStepperModule, MatStep } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DlDateTimeDateModule, DlDateTimeInputModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './http-interceptors/auth-interceptor';
import { InvoiceComponent } from './invoice/invoice.component';
import { InvoiceitemdetailComponent } from './invoiceitem/invoiceitemdetail/invoiceitemdetail.component';
import { InvoicelistComponent } from './invoicelist/invoicelist.component';
import { InvoiceItemComponent } from './invoiceitem/invoiceitem.component';
import { LoginComponent } from './login/login.component';
import { MasterComponent } from './master/master.component';
import { MasterdetailComponent } from './master/masterdetail/masterdetail.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SearchComponent } from './search/search.component';
import { ShellComponent } from './shell/shell.component';
import { TodoComponent } from './todo/todo.component';
import { TododetailComponent } from './todo/tododetail/tododetail.component';
import { EmailComponent } from './email/email.component';

@NgModule({
    declarations: [
        AppComponent,
        ShellComponent,
        InvoiceComponent,
        InvoiceItemComponent,
        InvoicelistComponent,
        LoginComponent,
        MasterComponent,
        SearchComponent,
        TodoComponent,
        MasterdetailComponent,
        InvoiceitemdetailComponent,
        TododetailComponent,
        EmailComponent
    ],
    imports: [
        AppRoutingModule,
        MatStepperModule,
        CdkStepperModule,
        MatFormFieldModule,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ToastrModule.forRoot({ maxOpened: 1, autoDismiss: true, preventDuplicates: true }),
        NgSelectModule,
        DlDateTimePickerModule,
        DlDateTimeDateModule,
        DlDateTimeInputModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'My-Xsrf-Cookie',
            headerName: 'My-Xsrf-Header',
        })
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
