import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InvoiceItemService {

    constructor(private http: HttpClient) { }

    getInvoiceItems(site: string, query: string): Observable<any[]> {
        return this.http.get<any[]>(environment.baseUrl + '/v1/' + site + '/invoiceitems'
            + (query ? '?q=' + query : ''));
    }

    getInvoiceItem(site: string, id: number): Observable<any> {
        return this.http.get<any>(environment.baseUrl + '/v1/' + site + '/invoiceitem/' + id);
    }

    updateInvoiceItem(site: string, item: any): Observable<any> {
        return this.http.put<any>(environment.baseUrl + '/v1/' + site + '/invoiceitem', item);
    }

    insertInvoiceItem(site: string, item: any): Observable<any> {
        return this.http.post<any>(environment.baseUrl + '/v1/' + site + '/invoiceitem', item);
    }

    deleteInvoiceItem(site: string, itemId: number): Observable<any> {
        return this.http.delete<any>(environment.baseUrl + '/v1/' + site + '/invoiceitem/' + itemId);
    }
}
