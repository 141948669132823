export class InvoiceItem {
    id: number;
    name: string;
    quantity: number;
    cost: number;
    sum: number;
    isWork: boolean;
}

export class Todo {
    id: number;
    text: string;
    invoiceItem: InvoiceItem = new InvoiceItem();
}
