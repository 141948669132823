<div *ngIf="loading">
    <img class="center-block" src="/assets/loader.gif" />
</div>

<div *ngIf="invoice.customer">

    <form #myForm="ngForm">
        <div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="customer">Kunde</label>
                        <br />
                        {{invoice.customer.name}}, {{invoice.customer.first}}<br />
                        <address id="customer">
                            {{invoice.customer.address.street}}<br />
                            {{invoice.customer.address.zip}} {{invoice.customer.address.city}}
                        </address>
                    </div>
                </div>

                <div class="col-sm-6" *ngIf="config && config.showCar">
                    <div class="form-group">
                        <label for="car">KFZ</label>
                        <select class="form-control" name="car" id="car" [(ngModel)]="invoice.selectedCar">
                            <option *ngFor="let car of invoice.customer.cars" [ngValue]="car.id">
                                {{car.carvendor}} {{car.carmodel}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">

                    <div class="form-group">
                        <label for="receivedDate">Annahme</label>
                        <div class="input-group">
                            <input id="receivedDate" name="receivedDate" type="text" class="form-control"
                                aria-label="Date" required dlDateTimeInput [(ngModel)]="invoice.receivedDate">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary dropdown-toggle date-dropdown" type="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                        class="oi oi-calendar"></i></button>
                                <div class="dropdown-menu" (click)="keepDropDownOpen($event)">
                                    <dl-date-time-picker name="datepicker" [(ngModel)]="invoice.receivedDate"
                                        minView="day" (change)="dateSelected($event)">
                                    </dl-date-time-picker>
                                </div>
                            </div>
                            <div class="invalid-feedback">
                                Please enter or choose a date and time after the current time.
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="no">Rechnungs-Nr</label>
                        <br />
                        <div *ngIf="(config && config.editInvoiceNo) && !update" class="input-group">
                            <input id="no" name="no" type="text" class="form-control" [(ngModel)]="invoice.no">
                        </div>
                        <div *ngIf="(config && !config.editInvoiceNo) || update">
                            {{invoice.no}}
                        </div>
                    </div>
                </div>

            </div>

            <div class="row" *ngIf="config && config.showCar">
                <div class="col-sm-6">

                    <div class="form-group">
                        <label for="km">Kilometerstand</label>
                        <div class="input-group">
                            <input id="km" name="km" type="text" class="form-control" [(ngModel)]="invoice.km">
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="form-group" *ngIf="config && config.showTodo">
            <label for="todos">Reparaturen</label>
            <ng-select name="todos" id="todos" [items]="todos$ | async" bindLabel="text" [addTag]="true"
                [hideSelected]="true" [multiple]="true" [trackByFn]="trackByFn" [minTermLength]="2"
                [loading]="todosLoading" (add)="onSelectTodo()" typeToSearchText="Please enter 2 or more characters"
                [typeahead]="todosInput$" [(ngModel)]="invoice.todos">
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    {{item.text}}
                </ng-template>
            </ng-select>
        </div>

        <div class="form-group">
            <label for="items">Rechnungsposten</label>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <td scope="col">Bezeichnung</td>
                        <td scope="col">Anzahl</td>
                        <td scope="col">Kosten</td>
                        <td colspan="2" scope="col">Summe &euro;</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of invoice.items; let i = index">
                        <td><input class="form-control" name="n{{i}}" type=text [(ngModel)]="item.name"> </td>
                        <td><input class="form-control" size="3" name="q{{i}}" type=number [(ngModel)]="item.quantity"
                                (change)="determineSum()"></td>
                        <td><input class="form-control" size="5" name="c{{i}}" type=number [(ngModel)]="item.cost"
                                (change)="determineSum()"></td>
                        <td>{{item.quantity * item.cost| number:'1.2-2'}}</td>
                        <td><button type="button" class="btn btn-danger" (click)="deleteItem(i)">-</button></td>
                    </tr>

                    <tr>
                        <td>
                            <ng-select name="items" id="items" [items]="invoiceItems$ | async" bindLabel="label"
                                [addTag]="true" [hideSelected]="true" [trackByFn]="trackByFn" [minTermLength]="1"
                                [loading]="invoiceItemsLoading" (close)="onSelectInvoiceItem()"
                                typeToSearchText="Please enter 1 or more characters" [typeahead]="invoiceItemsInput$"
                                [(ngModel)]="newitem">
                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                    {{item.name}}
                                </ng-template>
                            </ng-select>
                        </td>
                        <td>
                            <input class="form-control" size="3" name="quantity" type=text [(ngModel)]="newquantity">
                        </td>
                        <td>
                            <input class="form-control" size="6" name="cost" type=text [(ngModel)]="newcost">
                        </td>
                        <td colspan="2">&nbsp;</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="2">&nbsp;</td>
                        <td><b>Summe</b></td>
                        <td colspan="2">{{invoice.sum | number:'1.2-2'}} Euro</td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <div class="container">
            <div class="row in-button-footer">
                <div class="col">
                    <button type="button" class="btn btn-primary" *ngIf="!update" (click)="createInvoice()">Rechnung
                        erzeugen</button>
                    <button type="button" class="btn btn-primary" *ngIf="update" (click)="updateInvoice()">Rechnung
                        aktualisieren</button>
                    <img *ngIf="progress" class="center-block" src="/assets/loader.gif" /><br />
                    <button type="button" class="btn btn-primary" (click)="cancel()">
                        Abbrechen</button>
                </div>
                <div class="col">
                    <button type="button" class="btn btn-danger" *ngIf="update" (click)="deleteInvoice()"><span
                            class="material-icons">delete</span></button>
                </div>
            </div>
        </div>
    </form>
</div>