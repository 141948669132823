import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EmailComponent } from './email/email.component';
import { InvoiceItemComponent } from './invoiceitem/invoiceitem.component';
import { InvoiceitemdetailComponent } from './invoiceitem/invoiceitemdetail/invoiceitemdetail.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { InvoicelistComponent } from './invoicelist/invoicelist.component';
import { LoginComponent } from './login/login.component';
import { MasterComponent } from './master/master.component';
import { MasterdetailComponent } from './master/masterdetail/masterdetail.component';
import { SearchComponent } from './search/search.component';
import { ShellComponent } from './shell/shell.component';
import { TodoComponent } from './todo/todo.component';
import { TododetailComponent } from './todo/tododetail/tododetail.component';


const routes: Routes = [
    { path: '', redirectTo: 'kfz-technik-holste', pathMatch: 'full' },
    {
        path: '',
        component: ShellComponent,
        children: [
            { path: ':site/search', component: SearchComponent },

            { path: ':site/master', component: MasterComponent },
            { path: ':site/invoiceitem', component: InvoiceItemComponent },
            { path: ':site/todo', component: TodoComponent },

            { path: ':site/master/:id', component: MasterdetailComponent },
            { path: ':site/invoiceitem/:id', component: InvoiceitemdetailComponent },
            { path: ':site/todo/:id', component: TododetailComponent },
            { path: ':site/email/:customerId/:invoiceId', component: EmailComponent },

            { path: ':site/invoice/edit/:invoiceId', component: InvoiceComponent },
            { path: ':site/invoice/new/:customerId', component: InvoiceComponent },
            { path: ':site/list/:id', component: InvoicelistComponent },
            { path: ':site/list', component: InvoicelistComponent },
            { path: ':site', pathMatch: 'full', redirectTo: ':site/list' }
        ]
    },
    { path: ':site/login', component: LoginComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
