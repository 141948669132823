import { Component, OnInit, isDevMode } from '@angular/core';
import { InvoiceService } from '../invoice/invoice.service';
import { CustomerService } from '../customer.service';
import { Customer } from '../customer';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorService } from '../error.service';

import { environment } from '../../environments/environment';

@Component({
    selector: 'app-invoicelist',
    templateUrl: './invoicelist.component.html',
    styleUrls: ['./invoicelist.component.css']
})
export class InvoicelistComponent implements OnInit {

    site: string;
    loading: boolean;

    customerid: number;
    customer: any;
    invoices: any[];
    latestCustomers: any[];
    environment: any;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private invoiceService: InvoiceService,
        private customerService: CustomerService,
        private errorService: ErrorService
    ) {
        this.environment = environment;
    }

    ngOnInit(): void {

        this.site = this.route.snapshot.paramMap.get('site');

        this.invoices = [];
        this.customerid = +this.route.snapshot.paramMap.get('id');

        if (this.customerid) {
            this.loading = true;
            this.customerService.getCustomer(this.site, this.customerid).subscribe(
                c => {
                    this.loading = false;
                    this.selectPerson(c);
                },
                error => {
                    this.loading = false;
                    this.errorService.handleError(this.site, error);
                }
            );
        } else {
            this.loading = true;
            this.customerService.getLatestCustomers(this.site).subscribe(
                c => {
                    this.loading = false;
                    this.latestCustomers = c;
                },
                error => {
                    this.loading = false;
                    this.errorService.handleError(this.site, error);
                }
            );
        }
    }

    selectPerson(selectedPerson: Customer) {
        if (isDevMode()) { console.log('selectPerson ' + JSON.stringify(selectedPerson)); }
        this.customer = selectedPerson;
        if (selectedPerson) {

            if (selectedPerson.customerId) {
                // found customer
                if (isDevMode()) { console.log('found customer ' + selectedPerson.customerId); }
                this.loading = true;
                this.invoiceService.getInvoices(this.site, this.customer.customerId).subscribe(
                    invoices => {
                        this.loading = false;
                        this.invoices = invoices;
                    },
                    error => {
                        this.loading = false;
                        this.errorService.handleError(this.site, error);
                    }
                );
            } else {
                if (isDevMode()) { console.log('did not find customer ' + selectedPerson.customerId); }
                this.router.navigate(['/' + this.site + '/master/0']);
            }

        } else {
            this.router.navigate(['/' + this.site + '/list']);
        }
    }

    create() {
        this.router.navigate(['/' + this.site + '/invoice/new/' + this.customer.customerId]);
    }
}
