<div class="container">
    <nav class="navbar navbar-expand-lg navbar-light">
        <a class="navbar-brand" href="xxx">
            <img class="app-img"
                src="https://storage.googleapis.com/{{environment.bucketName}}/{{site}}/images/logo.png">
        </a>
        <button *ngIf="user" class="navbar-toggler" type="button" data-toggle="collapse"
            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div *ngIf="user" class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <a class="nav-link" [ngClass]="{ 'active': page=='invoice' }"
                        routerLink="/{{site}}/list">Rechnung</a></li>
                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <a class="nav-link" [ngClass]="{ 'active': page=='master' }"
                        routerLink="/{{site}}/master">Stammdaten</a></li>
                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <a class="nav-link" [ngClass]="{ 'active': page=='invoiceitem' }"
                        routerLink="/{{site}}/invoiceitem">Rechnungsposten</a></li>
                <li *ngIf="config && config.showTodo" class="nav-item" data-toggle="collapse"
                    data-target=".navbar-collapse.show">
                    <a class="nav-link" [ngClass]="{ 'active': page=='todo' }"
                        routerLink="/{{site}}/todo">Reparaturarbeiten</a></li>
                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.logout">
                    <button class="nav-link btn btn-link" (click)="logout()">Logout</button></li>
            </ul>
        </div>
    </nav>

    <router-outlet></router-outlet>

</div>