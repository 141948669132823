import { Component, Input, Output, OnInit, EventEmitter, isDevMode } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import { CustomerService } from '../customer.service';
import { Customer } from '../customer';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

    site: string;

    people$: Observable<Customer[]>;
    peopleLoading = false;
    peopleInput$ = new Subject<string>();
    selectedPerson: any;

    @Output() selectPerson = new EventEmitter();

    constructor(
        private route: ActivatedRoute,
        private customerService: CustomerService
    ) {
    }

    ngOnInit() {
        this.site = this.route.snapshot.paramMap.get('site');
        if (isDevMode()) { console.log('load ' + this.site); }
        this.loadPeople();
    }

    trackByFn(item: any) {
        return item.customerId;
    }

    private loadPeople() {
        this.people$ = concat(
            of([]), // default items
            this.peopleInput$.pipe(
                distinctUntilChanged(),
                tap(() => this.peopleLoading = true),
                switchMap(term => this.customerService.getCustomers(this.site, term).pipe(
                    catchError(() => of([])), // empty list on error
                    tap(() => this.peopleLoading = false)
                ))
            )
        );
    }

    onClose() {
        if (isDevMode()) { console.log('event=' + JSON.stringify(this.selectedPerson)); }
        this.selectPerson.emit(this.selectedPerson);
    }
}
