<div *ngIf="loading">
    <img class="center-block" src="/assets/loader.gif" />
</div>

<div class="form-group">
    <label for="rechnungsposten">Rechnungsposten</label>
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <td scope="col">Name</td>
                <td scope="col">Kosten</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of invoiceItems; let i = index">
                <td><a routerLink="/{{site}}/invoiceitem/{{item.id}}">{{item.name}}</a></td>
                <td>{{item.cost}} &euro;</td>
            </tr>

        </tbody>
    </table>
</div>

<div class="form-group">
    <button type="button" class="btn btn-primary" (click)="insert()">Neu</button>
</div>