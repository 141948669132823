import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Invoice, Item } from '../invoice';

import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InvoiceService {

    constructor(private http: HttpClient) { }

    getInvoices(site: string, customerId: number): Observable<any[]> {
        return this.http.get<any[]>(environment.baseUrl + '/v1/' + site + '/invoices/customer/' + customerId);
    }

    getInvoice(site: string, id: string): Observable<Invoice> {
        return this.http.get<Invoice>(environment.baseUrl + '/v1/' + site + '/invoice/' + id);
    }

    getInvoiceHtml(site: string, id: string): Observable<string> {
        return this.http.get<string>(environment.baseUrl + '/v1/' + site + '/invoice/' + id + '.html');
    }

    createInvoice(site: string, item: Invoice): Observable<any> {
        return this.http.post<any[]>(environment.baseUrl + '/v1/' + site + '/invoice', item);
    }

    updateInvoice(site: string, item: Invoice): Observable<any> {
        return this.http.put<any[]>(environment.baseUrl + '/v1/' + site + '/invoice', item);
    }

    deleteInvoice(site: string, item: Invoice): Observable<any> {
        return this.http.delete<any[]>(environment.baseUrl + '/v1/' + site + '/invoice/' + item.no);
    }

}
