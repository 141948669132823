import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';
import { Customer } from './customer';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    constructor(private http: HttpClient) { }

    getCustomers(site: string, query: string): Observable<Customer[]> {
        return this.http.get<Customer[]>(environment.baseUrl + '/v1/' + site + '/customers'
            + (query ? '?q=' + query : ''));
    }

    getAllCustomers(site: string): Observable<Customer[]> {
        return this.http.get<Customer[]>(environment.baseUrl + '/v1/' + site + '/customers');
    }

    getLatestCustomers(site: string): Observable<Customer[]> {
        return this.http.get<Customer[]>(environment.baseUrl + '/v1/' + site + '/customers/latest');
    }

    getCustomer(site: string, id: number): Observable<Customer> {
        return this.http.get<Customer>(environment.baseUrl + '/v1/' + site + '/customer/' + id);
    }

    updateCustomer(site: string, customer: Customer): Observable<any> {
        return this.http.put<Customer>(environment.baseUrl + '/v1/' + site + '/customer', customer);
    }

    insertCustomer(site: string, customer: Customer): Observable<Customer> {
        return this.http.post<Customer>(environment.baseUrl + '/v1/' + site + '/customer', customer);
    }

    deleteCustomer(site: string, customerId: number): Observable<Customer> {
        return this.http.delete<Customer>(environment.baseUrl + '/v1/' + site + '/customer/' + customerId);
    }
}
