import { Component, OnInit, isDevMode } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../app.service';

import { CustomerService } from '../../customer.service';
import { Customer, Address, Car } from '../../customer';
import { ErrorService } from '../../error.service';

@Component({
    selector: 'app-masterdetail',
    templateUrl: './masterdetail.component.html',
    styleUrls: ['./masterdetail.component.css']
})
export class MasterdetailComponent implements OnInit {

    site: string;
    loading: boolean;
    updateMode: boolean;
    editItem: number;

    item: Customer;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private appService: AppService,
        private customerService: CustomerService,
        private errorService: ErrorService
    ) { }

    config: any;

    ngOnInit(): void {
        this.site = this.route.snapshot.paramMap.get('site');
        const customerid = +this.route.snapshot.paramMap.get('id');
        this.loadConfig();
        if (customerid === 0) {
            this.updateMode = false;
            this.item = new Customer();
            this.item.cars = new Array();
            this.item.address = new Address();
        } else {
            this.updateMode = true;
            this.loadData(customerid);
        }
    }

    loadData(itemid: number) {
        this.loading = true;
        this.customerService.getCustomer(this.site, itemid).subscribe(
            c => {
                this.loading = false;
                if (isDevMode()) { console.log('item=' + JSON.stringify(c)); }
                this.item = c;
            },
            error => {
                this.loading = false;
                this.errorService.handleError(this.site, error);
            }
        );
    }

    private loadConfig() {
        this.appService.getConfig(this.site).subscribe(
            config => {
                this.config = config;
            }
        );
    }

    update() {
        this.customerService.updateCustomer(this.site, this.item).subscribe(
            c => {
                if (isDevMode()) { console.log('item=' + JSON.stringify(c)); }
                this.router.navigate(['/' + this.site + '/master']);
            },
            error => {
                this.loading = false;
                this.errorService.handleError(this.site, error);
            }
        );
    }

    insert() {
        this.customerService.insertCustomer(this.site, this.item).subscribe(
            c => {
                if (isDevMode()) { console.log('item=' + JSON.stringify(c)); }
                this.router.navigate(['/' + this.site + '/master']);
            },
            error => {
                this.loading = false;
                this.errorService.handleError(this.site, error);
            }
        );
    }

    delete() {
        if (confirm('Möchten Sie den Kunden wirklich löschen?') === true) {
            this.customerService.deleteCustomer(this.site, this.item.customerId).subscribe(
                c => {
                    if (isDevMode()) { console.log('item=' + JSON.stringify(c)); }
                    this.router.navigate(['/' + this.site + '/master']);
                },
                error => {
                    this.loading = false;
                    this.errorService.handleError(this.site, error);
                }
            );
        }
    }

    cancel() {
        this.router.navigate(['/' + this.site + '/master']);
    }

    addCar() {
        this.item.cars.push(new Car());
        if (isDevMode()) { console.log('addcar' + this.item.cars.length); }
        this.editItem = this.item.cars.length - 1;
    }

    deleteCar(i: number) {
        if (confirm('Möchten Sie das KFZ wirklich löschen?') === true) {
            this.item.cars.splice(i, 1);
        }
    }

    updateCar(i: number) {
        this.editItem = i;
    }
}
