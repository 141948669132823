import { Component, OnInit, isDevMode } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ErrorService } from '../../error.service';
import { InvoiceItemService } from '../invoiceitem.service';

@Component({
    selector: 'app-invoiceitemdetail',
    templateUrl: './invoiceitemdetail.component.html',
    styleUrls: ['./invoiceitemdetail.component.css']
})
export class InvoiceitemdetailComponent implements OnInit {

    site: string;
    loading: boolean;
    updateMode: boolean;

    item: any;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private errorService: ErrorService,
        private invoiceItemService: InvoiceItemService
    ) { }

    ngOnInit(): void {
        this.site = this.route.snapshot.paramMap.get('site');
        const itemid = +this.route.snapshot.paramMap.get('id');
        if (itemid === 0) {
            this.updateMode = false;
            this.item = {};
        } else {
            this.updateMode = true;
            this.loadData(itemid);
        }
    }

    loadData(itemId: number) {
        this.loading = true;
        this.invoiceItemService.getInvoiceItem(this.site, itemId).subscribe(
            c => {
                this.loading = false;
                if (isDevMode()) { console.log('item=' + JSON.stringify(c)); }
                this.item = c;
            },
            error => {
                this.loading = false;
                this.errorService.handleError(this.site, error);
            }
        );
    }

    update() {
        this.invoiceItemService.updateInvoiceItem(this.site, this.item).subscribe(
            c => {
                if (isDevMode()) { console.log('item=' + JSON.stringify(c)); }
                this.router.navigate(['/' + this.site + '/invoiceitem']);
            },
            error => {
                this.loading = false;
                this.errorService.handleError(this.site, error);
            }
        );
    }

    insert() {
        this.invoiceItemService.insertInvoiceItem(this.site, this.item).subscribe(
            c => {
                if (isDevMode()) { console.log('item=' + JSON.stringify(c)); }
                this.router.navigate(['/' + this.site + '/invoiceitem']);
            },
            error => {
                this.loading = false;
                this.errorService.handleError(this.site, error);
            }
        );
    }

    delete() {
        if (confirm('Möchten Sie den Rechnungsposten wirklich löschen?') === true) {
            this.invoiceItemService.deleteInvoiceItem(this.site, this.item.id).subscribe(
                c => {
                    if (isDevMode()) { console.log('item=' + JSON.stringify(c)); }
                    this.router.navigate(['/' + this.site + '/invoiceitem']);
                },
                error => {
                    this.loading = false;
                    this.errorService.handleError(this.site, error);
                }
            );
        }
    }

    cancel() {
        this.router.navigate(['/' + this.site + '/invoiceitem']);
    }
}
