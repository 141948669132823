import { Component, OnInit, isDevMode } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TodoService } from '../todo.service';
import { Todo } from 'src/app/invoiceitem';

@Component({
    selector: 'app-tododetail',
    templateUrl: './tododetail.component.html',
    styleUrls: ['./tododetail.component.css']
})
export class TododetailComponent implements OnInit {

    site: string;
    loading: boolean;
    updateMode: boolean;

    item: Todo;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private todoService: TodoService
    ) { }

    ngOnInit(): void {
        this.site = this.route.snapshot.paramMap.get('site');
        const itemid = +this.route.snapshot.paramMap.get('id');
        if (itemid === 0) {
            this.updateMode = false;
            this.item = new Todo();
        } else {
            this.updateMode = true;
            this.loadData(itemid);
        }
    }

    loadData(itemId: number) {
        this.loading = true;
        this.todoService.getTodo(this.site, itemId).subscribe(
            c => {
                this.loading = false;
                if (isDevMode()) { console.log('item=' + JSON.stringify(c)); }
                this.item = c;
            }
        );
    }

    update() {
        if (isDevMode()) { console.log('update todo=' + JSON.stringify(this.item)); }
        this.todoService.updateTodo(this.site, this.item).subscribe(
            c => {
                if (isDevMode()) { console.log('item=' + JSON.stringify(c)); }
                this.router.navigate(['/' + this.site + '/todo']);
            }
        );
    }

    insert() {
        this.todoService.insertTodo(this.site, this.item).subscribe(
            c => {
                if (isDevMode()) { console.log('item=' + JSON.stringify(c)); }
                this.router.navigate(['/' + this.site + '/todo']);
            }
        );
    }

    delete() {
        if (confirm('Möchten Sie die Reparaturarbeit wirklich löschen?') === true) {
            this.todoService.deleteTodo(this.site, this.item.id).subscribe(
                c => {
                    if (isDevMode()) { console.log('item=' + JSON.stringify(c)); }
                    this.router.navigate(['/' + this.site + '/todo']);
                }
            );
        }
    }

    cancel() {
        this.router.navigate(['/' + this.site + '/todo']);
    }
}
