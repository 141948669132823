import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

    constructor(
        private router: Router,
        private toastr: ToastrService
    ) { }

    handleError(site: string, error) {
        if (isDevMode()) { console.log('res=' + JSON.stringify(error)); }
        if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
                if (isDevMode()) { console.log('you are not authenticated, redirect to login'); }
                this.router.navigate(['/' + site + '/login']);
            } else {
                this.toastr.error(error.error.text + ' (' + error.status + ')', 'Fehler aufgetreten');
            }
        } else {
            this.toastr.error(error.error.text, 'Fehler aufgetreten');
        }
    }
}
