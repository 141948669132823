import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoiceItemService } from '../invoiceitem/invoiceitem.service';
import { ErrorService } from '../error.service';

@Component({
    selector: 'app-invoiceitem',
    templateUrl: './invoiceitem.component.html',
    styleUrls: ['./invoiceitem.component.css']
})
export class InvoiceItemComponent implements OnInit {

    site: string;
    loading: boolean;

    invoiceItems: any[];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private errorService: ErrorService,
        private invoiceItemService: InvoiceItemService
    ) { }

    ngOnInit() {
        this.site = this.route.snapshot.paramMap.get('site');
        this.loadData();
    }

    loadData() {
        this.loading = true;
        this.invoiceItemService.getInvoiceItems(this.site, null).subscribe(
            invoiceItems => {
                this.loading = false;
                this.invoiceItems = invoiceItems;
            },
            error => {
                this.loading = false;
                this.errorService.handleError(this.site, error);
            }
        );
    }

    insert() {
        this.router.navigate(['/' + this.site + '/invoiceitem/0']);
    }
}
