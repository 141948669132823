import { Component, OnInit, isDevMode } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ErrorService } from '../error.service';
import { EmailService } from './email.service';
import { CustomerService } from '../customer.service';

import { environment } from '../../environments/environment';

@Component({
    selector: 'app-email',
    templateUrl: './email.component.html',
    styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {

    environment: any;
    site: string;
    invoiceId: string;
    customerId: number;

    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    innerhtml: string;

    loading: boolean;
    progress: boolean;
    email: string;

    constructor(private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private domSanitizer: DomSanitizer,
        private customerService: CustomerService,
        private errorService: ErrorService,
        private emailService: EmailService
    ) {
        this.environment = environment;
    }

    ngOnInit(): void {
        this.site = this.route.snapshot.paramMap.get('site');
        this.customerId = +this.route.snapshot.paramMap.get('customerId');
        this.invoiceId = this.route.snapshot.paramMap.get('invoiceId');

        this.firstFormGroup = this.formBuilder.group({
            firstCtrl: ['', Validators.required]
        });
        this.secondFormGroup = this.formBuilder.group({
            secondCtrl: ['', Validators.required]
        });
        this.loadEmail();
    }

    loadEmail() {
        this.loading = true;
        this.customerService.getCustomer(this.site, this.customerId).subscribe(
            c => {
                this.loading = false;
                if (isDevMode()) { console.log('item=' + JSON.stringify(c)); }
                this.email = c.email;
            },
            error => {
                this.loading = false;
                this.errorService.handleError(this.site, error);
            }
        );
    }

    photoURL(): SafeUrl {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(
            environment.baseUrl + '/v1/invoice/' + this.site + '/'
            + this.invoiceId + '.html');
    }

    send() {
        this.progress = true;
        this.emailService.sendInvoice(this.site, this.invoiceId, this.email).subscribe(
            ok => {
                this.progress = false;
                if (isDevMode()) { console.log('send invoice'); }
                this.router.navigate(['/' + this.site + '/list/' + this.customerId]);
            },
            error => {
                this.progress = false;
                this.errorService.handleError(this.site, error);
            }
        );
    }

    cancel() {
        this.router.navigate(['/' + this.site + '/list/' + this.customerId]);
    }
}
