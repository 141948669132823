<div *ngIf="item">
    <form #myForm="ngForm">
        <div class="form-group">
            <label for="text">Name</label>
            <input class="form-control" name="name" type=text [(ngModel)]="item.name">
        </div>
        <div class="form-group">
            <label for="text">Anzahl</label>
            <input class="form-control" name="quantity" type=number [(ngModel)]="item.quantity">
        </div>
        <div class="form-group">
            <label for="text">Kosten</label>
            <input class="form-control" name="cost" type=number [(ngModel)]="item.cost">
        </div>
        <div class="form-group">
            <div class="checkbox">
                <label>
                    <input type="checkbox" name="isWork" [(ngModel)]="item.isWork" /> Arbeitsposten?
                </label>
            </div>
        </div>

        <div class="container">
            <div class="row in-button-footer">
                <div class="col">
                    <button type="button" class="btn btn-primary" (click)="update()">Aktualisieren</button>
                    <button type="button" class="btn btn-primary" (click)="cancel()">Abbrechen</button>
                </div>
                <div class="col">
                    <button type="button" class="btn btn-danger" (click)="delete()"><span
                            class="material-icons">delete</span>
                    </button>
                </div>
            </div>
        </div>

    </form>

</div>