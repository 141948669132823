export class Address {
    street: string;
    street1: string;
    city: string;
    zip: number;
}

export class Car {
    id: number;
    carvendor: string;
    carmodel: string;
    carpower: string;
    carid: string;
    ez: string;
    ccm: string;
    serial: string;
}

export class Customer {
    customerId: number;
    sex: string;
    name: string;
    first: string;
    label: string;
    email: string;

    address: Address = new Address();
    cars: Car[] = [];
}
