<div *ngIf="loading">
    <img class="center-block" src="/assets/loader.gif" />
</div>

<div class="form-group">
    <label for="todos">Reparaturarbeiten</label>
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <td scope="col">Reparaturarbeit</td>
                <td scope="col">Rechnungsposten</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of parts; let i = index">
                <td><a routerLink="/{{site}}/todo/{{item.id}}">{{item.text}}</a></td>
                <td><span *ngIf="item.invoiceItem && item.invoiceItem.name">{{item.invoiceItem.name}}<br />
                        <small> {{item.invoiceItem.cost}} &euro;</small></span></td>
            </tr>

        </tbody>
    </table>
</div>

<div class="form-group">
    <button type="button" class="btn btn-primary" (click)="insert()">Neu</button>
</div>