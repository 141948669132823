import { Customer } from './customer'

export class Item {
    name: string;
    quantity: number;
    cost: number;
}

export class Invoice {
    no: string;
    customer: Customer;
    items: Item[] = [];
    todos: any[] = [];
    sum: number;
    km: number;
    selectedCar: any;
    receivedDate: Date;
}
