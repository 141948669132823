<app-search (selectPerson)="selectPerson($event)"></app-search>

<div *ngIf="customerid && customer" class="form-group">
    {{customer.name}}, {{customer.first}}<br />
    <address>
        {{customer.address.street}}<br />
        {{customer.address.zip}} {{customer.address.city}}
    </address>
</div>

<div *ngIf="customer && customer.customerId">
    <div *ngIf="loading">
        <img class="center-block" src="/assets/loader.gif" />
    </div>

    <div class="form-group">
        <label>Rechnungen</label>
    </div>
    <table class="table table-striped">
        <thead *ngIf="!invoices || invoices.length==0">
            <tr>
                <td>keine Rechnungen gefunden</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let invoice of invoices; let i = index">
                <td colspan="2">
                    <a target="invoice"
                        href="{{environment.baseUrl}}/v1/{{site}}/invoice/{{invoice.no}}.html">{{invoice.no}}
                    </a>
                </td>
                <td>{{invoice.invoiceDate | date}}</td>
                <td class="nopad">
                    <a routerLink="/{{site}}/email/{{invoice.customer.customerId}}/{{invoice.no}}">
                        <span class="material-icons">email</span>
                    </a>
                </td>
                <td class="nopad">
                    <a target="invoice" href="{{environment.baseUrl}}/v1/{{site}}/invoice/{{invoice.no}}.pdf">
                        <span class="material-icons">picture_as_pdf</span>
                    </a>
                </td>
                <td class="nopad">
                    <a routerLink="/{{site}}/invoice/edit/{{invoice.no}}">
                        <span class="material-icons">edit</span>
                    </a>
                </td>
            </tr>

        </tbody>
    </table>

    <div class="form-group">
        <button type="button" class="btn btn-primary" (click)="create()">Neue Rechnung</button>
    </div>
</div>

<div *ngIf="!customer || !customer.customerId">
    <div *ngIf="latestCustomers && latestCustomers.length > 0" class="form-group">
        <label>Letzte Kunden</label>
    </div>
    <div *ngIf="loading">
        <img class="center-block" src="/assets/loader.gif" />
    </div>
    <table class="table table-striped">
        <tbody>
            <tr *ngFor="let c of latestCustomers; let i = index">
                <td><a routerLink="/{{site}}/list/{{c.customerId}}">{{c.label}} ({{c.address.city}})</a>
                </td>
            </tr>

        </tbody>
    </table>
</div>