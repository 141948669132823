import { Component, OnInit, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';
import { ErrorService } from '../error.service';
import { LoginService } from '../login/login.service';
import { User } from '../login/user';

import { environment } from '../../environments/environment';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.css']
})
export class ShellComponent implements OnInit {

    page: string;
    site: string;
    config: any;
    user: User;
    loading: boolean;
    environment: any;

    constructor(
        private router: Router,
        private location: Location,
        private appService: AppService,
        private errorService: ErrorService,
        private loginService: LoginService
    ) {
        this.environment = environment;
    }

    ngOnInit() {
        this.user = this.loginService.user;
        this.loginService.loginEvent$.subscribe(
            user => {
                if (isDevMode()) { console.log('user=' + user); }
                this.user = user;
            }
        );

        // site
        let t = this.location.path(false).substring(1);
        let remainingPath: string;
        const pos = t.indexOf('/');
        if (pos > 1) {
            if (pos < t.length - 1) {
                remainingPath = t.substring(pos + 1);
            } else {
                remainingPath = '';
            }
            t = t.substring(0, pos);
        } else {
            remainingPath = '';
        }

        if (isDevMode()) { console.log('remainingPath=' + remainingPath); }
        if (remainingPath.indexOf('/') > 1) {
            this.page = remainingPath.substring(0, remainingPath.indexOf('/'));
        } else {
            this.page = remainingPath;
        }
        if (pos > 1) {
            t = t.substring(0, pos);
        }
        this.site = t;
        if (isDevMode()) { console.log('site=' + this.site); }

        if (!this.loginService.user) {
            this.loadUser();
        } else {
            this.loadConfig();
        }
    }

    loadUser() {
        if (isDevMode()) { console.log('getting user'); }
        this.loginService.getUser(this.site).subscribe(
            user => {
                this.loading = false;
                this.user = user;
                this.loginService.loginEvent(user);
                this.loadConfig();
            },
            error => {
                this.loading = false;
                this.errorService.handleError(this.site, error);
            }
        );
    }

    private loadConfig() {
        this.appService.getConfig(this.site).subscribe(
            config => {
                this.config = config;
            },
            error => {
                this.errorService.handleError(this.site, error);
            }
        );
    }

    logout() {
        if (isDevMode()) { console.log('logout'); }
        this.loginService.logout().subscribe(
            config => {
                this.user = null;

                this.router.navigate(['/' + this.site + '/login']);
            },
            error => {
                this.errorService.handleError(this.site, error);
            }
        );
    }
}
