import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Todo } from '../invoiceitem';

@Injectable({
    providedIn: 'root'
})
export class TodoService {

    constructor(private http: HttpClient) { }

    getTodos(site: string, query: string): Observable<Todo[]> {
        return this.http.get<Todo[]>(environment.baseUrl + '/v1/' + site + '/todos'
            + (query ? '?q=' + query : ''));
    }

    getTodo(site: string, id: number): Observable<Todo> {
        return this.http.get<Todo>(environment.baseUrl + '/v1/' + site + '/todo/' + id);
    }

    updateTodo(site: string, todo: any): Observable<Todo> {
        return this.http.put<Todo>(environment.baseUrl + '/v1/' + site + '/todo', todo);
    }

    insertTodo(site: string, todo: any): Observable<Todo> {
        return this.http.post<Todo>(environment.baseUrl + '/v1/' + site + '/todo', todo);
    }

    deleteTodo(site: string, todoId: number): Observable<any> {
        return this.http.delete<any>(environment.baseUrl + '/v1/' + site + '/todo/' + todoId);
    }
}
