import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    constructor(private http: HttpClient) { }

    getConfig(site: string): Observable<any> {
        return this.http.get<any[]>(environment.baseUrl + '/v1/' + site + '/config');
    }

    startReportService(site: string): Observable<any> {
        return this.http.get<any>(environment.baseUrl + '/util/store/' + site + '?date=' + new Date());
    }
}
