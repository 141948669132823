import { Component, OnInit, isDevMode } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';
import { CustomerService } from '../customer.service';
import { ErrorService } from '../error.service';
import { InvoiceService } from './invoice.service';
import { InvoiceItemService } from '../invoiceitem/invoiceitem.service';
import { TodoService } from '../todo/todo.service';
import { Invoice, Item } from '../invoice';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { DateButton } from 'angular-bootstrap-datetimepicker';
import * as _moment from 'moment';
import { unitOfTime } from 'moment';

let moment = _moment;

if ('default' in _moment) {
    moment = _moment['default'];
}

@Component({
    selector: 'app-invoice',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {

    site: string;
    loading: boolean;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private appService: AppService,
        private errorService: ErrorService,
        private customerService: CustomerService,
        private todoService: TodoService,
        private invoiceService: InvoiceService,
        private invoiceItemService: InvoiceItemService
    ) { }

    config: any;

    customerid: number;
    invoice: Invoice;
    update: boolean;

    // reparaturen drop down
    todos$: Observable<any[]>;
    todosLoading = false;
    todosInput$ = new Subject<string>();

    // rechnungsposten drop down
    invoiceItems$: Observable<any[]>;
    invoiceItemsLoading = false;
    invoiceItemsInput$ = new Subject<string>();

    // new rechnungsposten
    newitem: any;
    newcost: any;
    newquantity: string;

    stopPropagation = true;
    progress: boolean;

    startedReportService = 0;

    ngOnInit(): void {

        this.site = this.route.snapshot.paramMap.get('site');

        this.invoice = new Invoice();
        this.loadConfig();
        this.loadTodos();
        this.loadInvoiceItems();

        // set if new invoice
        this.customerid = +this.route.snapshot.paramMap.get('customerId');

        // set if edit invoice
        const invoiceId = this.route.snapshot.paramMap.get('invoiceId');

        if (isDevMode()) { console.log('customerId=' + this.customerid); }
        if (isDevMode()) { console.log('invoiceId=' + invoiceId); }
        if (invoiceId) {
            // update existing invoice
            this.update = true;
            if (isDevMode()) { console.log('loading invoice ' + invoiceId); }
            this.loading = true;
            this.invoiceService.getInvoice(this.site, invoiceId).subscribe(
                inv => {
                    this.loading = false;
                    inv.receivedDate = new Date(inv.receivedDate);
                    this.invoice = inv;
                    this.customerid = inv.customer.customerId;

                    this.customerService.getCustomer(this.site, this.customerid).subscribe(
                        customer => {
                            this.invoice.customer = customer;
                        }
                    );
                    this.determineSum();
                },
                error => {
                    this.loading = false;
                    this.errorService.handleError(this.site, error);
                }
            );

        } else {
            // create new invoice
            this.update = false;
            if (isDevMode()) { console.log('loading customer' + this.customerid); }
            this.loading = true;
            this.customerService.getCustomer(this.site, this.customerid).subscribe(
                c => {
                    this.loading = false;
                    this.invoice = new Invoice();
                    this.invoice.receivedDate = new Date();
                    this.invoice.customer = c;
                },
                error => {
                    this.loading = false;
                    this.errorService.handleError(this.site, error);
                }
            );
        }

    }

    trackByFn(item: any) {
        return item.id;
    }

    private loadInvoiceItems() {
        if (isDevMode()) { console.log('loadInvoiceItems'); }
        this.invoiceItems$ = concat(
            of([]), // default items
            this.invoiceItemsInput$.pipe(
                distinctUntilChanged(),
                tap(() => this.invoiceItemsLoading = true),
                switchMap(term => this.invoiceItemService.getInvoiceItems(this.site, term).pipe(
                    catchError(() => of([])), // empty list on error
                    tap(() => this.invoiceItemsLoading = false)
                ))
            )
        );
    }

    private loadTodos() {
        if (isDevMode()) { console.log('loadTodos'); }
        this.todos$ = concat(
            of([]), // default items
            this.todosInput$.pipe(
                distinctUntilChanged(),
                tap(() => this.todosLoading = true),
                switchMap(term => this.todoService.getTodos(this.site, term).pipe(
                    catchError(() => of([])), // empty list on error
                    tap(() => this.todosLoading = false)
                ))
            )
        );
    }

    private loadConfig() {
        this.appService.getConfig(this.site).subscribe(
            config => {
                this.config = config;
            }
        );
    }

    private startReportService() {
        // just ping the service in order to reduce startup time
        this.appService.startReportService(this.site).subscribe(
            ok => {
                if (isDevMode()) { console.log('started report service'); }
            }
        );
    }

    onSelectTodo() {
        if (isDevMode()) { console.log('onSelectTodo=' + (typeof this.invoice.todos) + JSON.stringify(this.invoice.todos)); }
        const last = this.invoice.todos[this.invoice.todos.length - 1];

        if (typeof last === 'string') {
            // do nothing
        } else if (last) {

            if (last.invoiceItem.name) {
                const item = last.invoiceItem;
                this.invoice.items.push(item);

                this.determineSum();
            }
        }
    }

    onSelectInvoiceItem() {
        if (isDevMode()) { console.log('onSelectInvoiceItem=' + (typeof this.newitem) + JSON.stringify(this.newitem)); }

        let item: Item = new Item();
        if (typeof this.newitem === 'string') {
            item.name = this.newitem;
            item.cost = 0;
            this.newitem = null;
            item.quantity = 1;
            this.invoice.items.push(item);
        } else if (this.newitem) {
            item = this.newitem;
            this.newitem = null;
            item.quantity = 1;
            this.invoice.items.push(item);
        }

        this.determineSum();

        if (this.startedReportService++ % 3 == 0) {
            this.startReportService();
        }
    }

    determineSum() {
        let s = 0;
        this.invoice.items.forEach(i => { s += (i.quantity * i.cost); });
        this.invoice.sum = s;
    }

    createInvoice() {
        this.progress = true;
        this.invoiceService.createInvoice(this.site, this.invoice).subscribe(
            ok => {
                this.progress = false;
                if (isDevMode()) { console.log('ok' + JSON.stringify(ok)); }
                this.router.navigate(['/' + this.site + '/list/' + this.customerid]);
            },
            error => {
                this.progress = false;
                this.errorService.handleError(this.site, error);
            }
        );
    }

    updateInvoice() {
        this.progress = true;
        this.invoiceService.updateInvoice(this.site, this.invoice).subscribe(
            ok => {
                this.progress = false;
                if (isDevMode()) { console.log('ok' + JSON.stringify(ok)); }
                this.router.navigate(['/' + this.site + '/list/' + this.customerid]);
            },
            error => {
                this.progress = false;
                this.errorService.handleError(this.site, error);
            }
        );
    }

    deleteInvoice() {
        if (confirm('Möchten Sie die Rechnung wirklich löschen?') === true) {
            this.invoiceService.deleteInvoice(this.site, this.invoice).subscribe(
                ok => {
                    if (isDevMode()) { console.log('ok' + JSON.stringify(ok)); }
                    this.router.navigate(['/' + this.site + '/list/' + this.customerid]);
                },
                error => {
                    this.loading = false;
                    this.errorService.handleError(this.site, error);
                }
            );
        }
    }

    cancel() {
        this.router.navigate(['/' + this.site + '/list/' + this.customerid]);
    }

    deleteItem(index: number) {
        if (isDevMode()) { console.log('delete item'); }
        this.invoice.items.splice(index, 1);
        this.determineSum();
    }

    dateSelected(event) {
        if (isDevMode()) { console.log('dateChange' + JSON.stringify(event)); }
        this.stopPropagation = false;
    }

    keepDropDownOpen(event) {
        if (isDevMode()) { console.log('keepDropDownOpen' + JSON.stringify(event)); }
        if (this.stopPropagation) {
            event.stopPropagation();
        }
        this.stopPropagation = true;
    }

    datePickerFilter = (dateButton: DateButton, viewName: string) => {
        return dateButton.value >= moment().startOf(
            viewName as unitOfTime.StartOf).valueOf();
    }
}
