<div *ngIf="loading">
    <img class="center-block" src="/assets/loader.gif" />
</div>

<div *ngIf="item">
    <form #myForm="ngForm">

        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="name">Name</label>
                    <input class="form-control" name="name" type=text [(ngModel)]="item.name">
                </div>
                <div class="form-group">
                    <label for="first">Vorname</label>
                    <input class="form-control" name="first" type=text [(ngModel)]="item.first">
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="street">Strasse</label>
                    <input class="form-control" name="street" type=text [(ngModel)]="item.address.street">
                </div>
                <div class="form-group">
                    <label for="street1">Zusatz</label>
                    <input class="form-control" name="street1" type=text [(ngModel)]="item.address.street1">
                </div>
                <div class="form-group">
                    <label for="zip">PLZ</label>
                    <input class="form-control" name="zip" type=text [(ngModel)]="item.address.zip">
                </div>
                <div class="form-group">
                    <label for="city">Stadt</label>
                    <input class="form-control" name="city" type=text [(ngModel)]="item.address.city">
                </div>
                <div class="form-group">
                    <label for="email">Email</label>
                    <input class="form-control" name="email" type=text [(ngModel)]="item.email">
                </div>
            </div>
        </div>

        <div class="form-group" *ngIf="config && config.showCar">
            <label for="kunde">KFZ</label>
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <td scope="col">Hersteller, Modell</td>
                        <td scope="col" colspan="5">Kennzeichen (Erstzulassung)</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let car of item.cars; let i = index">
                        <td>
                            <div *ngIf="editItem==i" class="form-group">
                                <label for="vendor">Hersteller</label>
                                <input class="form-control" name="vendor" type=text [(ngModel)]="car.carvendor">
                            </div>
                            <div *ngIf="editItem==i" class="form-group">
                                <label for="carmodel">Modell</label>
                                <input class="form-control" name="carmodel" type=text [(ngModel)]="car.carmodel">
                            </div>
                            <div *ngIf="editItem!=i">
                                {{car.carvendor}}, {{car.carmodel}}
                            </div>
                        </td>
                        <td colspan="3">
                            <div *ngIf="editItem==i" class="form-group">
                                <label for="carpower">Kennzeichen</label>
                                <input class="form-control" name="carpower" type=text [(ngModel)]="car.carid">
                            </div>
                            <div *ngIf="editItem==i" class="form-group">
                                <label for="carpower">Leistung</label>
                                <input class="form-control" name="carpower" type=text [(ngModel)]="car.carpower">
                            </div>
                            <div *ngIf="editItem==i" class="form-group">
                                <label for="serial">FG.-Nr.</label>
                                <input class="form-control" name="serial" type=text [(ngModel)]="car.serial">
                            </div>
                            <div *ngIf="editItem==i" class="form-group">
                                <label for="ccm">Hubraum</label>
                                <input class="form-control" name="ccm" type=text [(ngModel)]="car.ccm">
                            </div>
                            <div *ngIf="editItem==i" class="form-group">
                                <label for="ez">Erstzulassung</label>
                                <input class="form-control" name="ez" type=text [(ngModel)]="car.ez">
                            </div>

                            <div *ngIf="editItem!=i">
                                {{car.carid}} ({{car.ez}})
                            </div>
                        </td>
                        <td class="nopad">
                            <a (click)="updateCar(i)">
                                <span class="material-icons">edit</span>
                            </a>
                        </td>
                        <td class="nopad">
                            <a (click)="deleteCar(i)">
                                <span class="material-icons">delete</span>
                            </a>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="5">&nbsp;</td>
                        <td>
                            <a (click)="addCar()"><span class="material-icons">plus_one</span></a>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <div class="container">
            <div class="row in-button-footer">
                <div class="col">
                    <button *ngIf="updateMode" type="button" class="btn btn-primary"
                        (click)="update()">Aktualisieren</button>
                    <button *ngIf="!updateMode" type="button" class="btn btn-primary"
                        (click)="insert()">Anlegen</button>
                    <button type="button" class="btn btn-primary" (click)="cancel()">Abbrechen</button>
                </div>
                <div class="col">
                    <button *ngIf="updateMode" type="button" class="btn btn-danger" (click)="delete()"><span
                            class="material-icons">delete</span></button>
                </div>
            </div>
        </div>
    </form>
</div>