import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../customer.service';
import { ErrorService } from '../error.service';

@Component({
    selector: 'app-master',
    templateUrl: './master.component.html',
    styleUrls: ['./master.component.css']
})
export class MasterComponent implements OnInit {

    site: string;
    loading: boolean;

    customers: any[];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private customerService: CustomerService,
        private errorService: ErrorService
    ) { }

    ngOnInit() {
        this.site = this.route.snapshot.paramMap.get('site');
        this.loadData();
    }

    loadData() {
        this.loading = true;
        this.customerService.getAllCustomers(this.site).subscribe(
            customers => {
                this.loading = false;
                this.customers = customers;
            },
            error => {
                this.loading = false;
                this.errorService.handleError(this.site, error);
            }
        );
    }

    insert() {
        this.router.navigate(['/' + this.site + '/master/0']);
    }
}
