<form #myForm="ngForm">
    <div class="form-group">
        <label for="exampleFormControlInput1">Kunde</label>
        <ng-select name="search" id="search" [items]="people$ | async" bindLabel="label" [addTag]="true"
            [hideSelected]="true" [trackByFn]="trackByFn" [minTermLength]="2" [loading]="peopleLoading"
            (close)="onClose()" typeToSearchText="Please enter 2 or more characters" [typeahead]="peopleInput$"
            [(ngModel)]="selectedPerson">

            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div style="margin: 10px 0;" class="card">
                    <div class="card-body">
                        <h5 class="card-title">{{item.name}}, {{item.first}}</h5>
                        <h6 class="card-subtitle mb-2 text-muted">{{item.address.street}}</h6>
                        <p class="card-text">
                            <!-- img height="15" width="15" [src]="item.avatar" /-->
                            {{item.address.zip}} {{item.address.city}}
                        </p>
                    </div>
                </div>
            </ng-template>

        </ng-select>
    </div>

</form>