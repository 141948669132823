<mat-horizontal-stepper #stepper>
    <mat-step [stepControl]="firstFormGroup" label="Rechnung prüfen">
        <iframe class="iframe" [src]="photoURL()"></iframe>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup" label="Versenden">
        <div class="form-group">
            <label for="receivedDate">Email</label>
            <div class="input-group">
                <input id="email" name="email" type="email" required class="form-control" aria-label="email" required
                    [(ngModel)]="email">
            </div>

            <div class="container">
                <div class="row in-button-footer">
                    <div class="col">
                        <button type="button" class="btn btn-primary" (click)="send()">Rechnung
                            versenden</button>
                        <img *ngIf="progress" class="center-block" src="/assets/loader.gif" /><br />
                    </div>
                    <div class="col">
                        <button type="button" class="btn btn-primary" (click)="cancel()">
                            Abbrechen</button>
                    </div>
                </div>
            </div>
        </div>

    </mat-step>
</mat-horizontal-stepper>