import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EmailService {

    constructor(private http: HttpClient) { }

    sendInvoice(site: string, invoiceId: string, email: string): Observable<void> {
        return this.http.post<void>(environment.baseUrl + '/v1/invoice/' + site + '/send/' + invoiceId,
            email);
    }
}
